import React from 'react';
import Helmet from 'react-helmet';

export default function Template({ data }) {
    // const { markdownRemark: recipe } = data;

    const { markdownRemark } = data 
    const { frontmatter, html } = markdownRemark
    return (
        <div>
            <Helmet>
                <title>{frontmatter.title}</title>
            </Helmet>
            <div className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
            />

            {frontmatter.og &&
                <h2>OG badge</h2>
            }
        </div>
    )
}

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                og
            }
            fields {
                slug
            }
        }
    }
`